import React from 'react';
import logo from '../../assets/images/logo.png';

const AboutUs = () => {
  return (
    <div className="bg-white pb-2 sm:pb-4 lg:pb-6">
      <div className="max-w-screen-2xl px-4 md:px-8 mx-auto">
        <header className="flex justify-between items-center border-b py-4 md:py-8 mb-8 md:mb-12 xl:mb-16">
          {/* logo - start */}
          <a href="/" className="inline-flex items-center text-black-800 text-2xl md:text-3xl font-bold gap-2.5" aria-label="logo">
            <img src={logo} alt="Wattod" width="170px" height="34px" />
          </a>
          {/* logo - end */}
          {/* nav - start */}
          <nav className="hidden lg:flex gap-12">
            <a href="/services" className="text-gray-600 hover:text-orange-600 active:text-orange-700 text-base font-semibold transition duration-100">
              Brands
            </a>
            <a href="/" className="text-gray-600 hover:text-orange-600 active:text-orange-700 text-base font-semibold transition duration-100">
              Solutions
            </a>
            <a href="/" className="text-gray-600 hover:text-orange-600 active:text-orange-700 text-base font-semibold transition duration-100">
              Laboratory
            </a>
            <a href="/" className="text-gray-600 hover:text-orange-600 active:text-orange-700 text-base font-semibold transition duration-100">
              Studies
            </a>
            <a href="/about-us" className="text-gray-600 hover:text-orange-600 active:text-orange-700 text-base font-semibold transition duration-100">
              About Us
            </a>
          </nav>
          {/* nav - end */}
          {/* buttons - start */}
          <a
            href="about:blank"
            className="hidden lg:inline-block bg-orange-500 hover:bg-orange-600 focus-visible:ring ring-orange-300 text-white active:text-gray-700 text-sm md:text-sm font-semibold text-center rounded-lg outline-none transition duration-100 px-8 py-3"
          >
            Contact Us
          </a>
          <button type="button" className="inline-flex items-center lg:hidden bg-gray-200 hover:bg-gray-300 focus-visible:ring ring-orange-300 text-gray-500 active:text-gray-700 text-sm md:text-base font-semibold rounded-lg gap-2 px-2.5 py-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
            </svg>
            Menu
          </button>
          {/* buttons - end */}
        </header>
      </div>
    </div>
  );
};

export default AboutUs;
