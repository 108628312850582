import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from './screens/Home';
import './index.css';
import Services from './screens/Services';
import AboutUs from './screens/AboutUs';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Wattod: Next Generation Technology</title>
      <link rel="canonical" href="https://lesani.net" />
    </Helmet>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about-us" element={<AboutUs />} />
      </Routes>
    </BrowserRouter>
  </>,
);
