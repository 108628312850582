import React from 'react';
import team1 from '../../assets/images/team1.jpg';
import team2 from '../../assets/images/team2.jpg';
import team3 from '../../assets/images/team3.jpg';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const AboutUs = () => {
  return (
    <div>
      <Header />
      <div className="bg-white pb-6 sm:pb-8 lg:pb-12">
        <div className="mx-auto max-w-screen-xl px-4 md:px-8">
          {/* text - start */}
          <div className="mb-10 md:mb-16">
            <h2 className="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl">Meet our Team</h2>
            <p className="mx-auto max-w-screen-md text-center text-gray-500 md:text-lg">
              This is a section of some simple filler text, also known as placeholder text. It shares some characteristics of a real written text but is random or otherwise generated.
            </p>
          </div>
          {/* text - end */}
          <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-3 lg:gap-8">
            {/* person - start */}
            <div className="flex flex-col items-center rounded-lg bg-gray-100 p-4 lg:p-8">
              <div className="mb-2 h-24 w-24 overflow-hidden rounded-full bg-gray-200 shadow-lg md:mb-4 md:h-32 md:w-32">
                <img src={team1} alt="Mustafa" loading="lazy" className="h-full w-full object-cover object-center" />
              </div>
              <div>
                <div className="text-center font-bold text-indigo-500 md:text-lg">Mustafa Coşar</div>
                <p className="mb-3 text-center text-sm text-gray-500 md:mb-4 md:text-base">Founder & CEO</p>
                {/* social - start */}
                <div className="flex justify-center">
                  <div className="flex gap-4">
                    <a href="https://www.linkedin.com/in/mustafa-co%C5%9Far-2043421a2/" target="_blank" rel="noreferrer" className="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
                      <svg className="h-5 w-5" width={24} height={24} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                      </svg>
                    </a>
                  </div>
                </div>
                {/* social - end */}
              </div>
            </div>
            {/* person - end */}
            {/* person - start */}
            <div className="flex flex-col items-center rounded-lg bg-gray-100 p-4 lg:p-8">
              <div className="mb-2 h-24 w-24 overflow-hidden rounded-full bg-gray-200 shadow-lg md:mb-4 md:h-32 md:w-32">
                <img src={team2} alt="Salih" loading="lazy" className="h-full w-full object-cover object-center" />
              </div>
              <div>
                <div className="text-center font-bold text-indigo-500 md:text-lg">Salih Tekin</div>
                <p className="mb-3 text-center text-sm text-gray-500 md:mb-4 md:text-base">Applicative Software Developer</p>
                {/* social - start */}
                <div className="flex justify-center">
                  <div className="flex gap-4">
                    <a href="https://www.linkedin.com/in/salih-tekin-977347230/" target="_blank" rel="noreferrer" className="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
                      <svg className="h-5 w-5" width={24} height={24} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                      </svg>
                    </a>
                  </div>
                </div>
                {/* social - end */}
              </div>
            </div>
            {/* person - end */}
            {/* person - start */}
            <div className="flex flex-col items-center rounded-lg bg-gray-100 p-4 lg:p-8">
              <div className="mb-2 h-24 w-24 overflow-hidden rounded-full bg-gray-200 shadow-lg md:mb-4 md:h-32 md:w-32">
                <img src={team3} alt="Alperen" loading="lazy" className="h-full w-full object-cover object-center" />
              </div>
              <div>
                <div className="text-center font-bold text-indigo-500 md:text-lg">Alperen Erturan</div>
                <p className="mb-3 text-center text-sm text-gray-500 md:mb-4 md:text-base">Applicative Software Developer</p>
                {/* social - start */}
                <div className="flex justify-center">
                  <div className="flex gap-4">
                    <a href="/about-us" target="_blank" className="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
                      <svg className="h-5 w-5" width={24} height={24} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                      </svg>
                    </a>
                  </div>
                </div>
                {/* social - end */}
              </div>
            </div>
            {/* person - end */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
